var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InsuranceReport',{ref:"report",attrs:{"baseDate":_vm.baseDate}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 order-lg-2 order-xl-1"},[_c('KTPortlet',{class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('GroupingTransactionDataTable',_vm._b({ref:"inoutTable"},'GroupingTransactionDataTable',{
              baseDate: _vm.baseDate,
              titleText: '보험업권 입출거래 상세',
              titleTextClass: 'text-seizepurple',
              detailData: _vm.detailInputDataArray,
              categoryName: '보험업권',
              filterDisabled: {
                term: false,
                inout: false,
                targetInst: false,
                name: false,
                level2: false,
                level3: false,
                level4: false,
                level5: false,
                tranType: false,
              },
              inoutInitialFilter: ['입금', '출금'],
              itemsPerPage: 10,
            },false))]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }